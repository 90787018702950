<template>
  <the-header></the-header>
  <base-dialog fixed :show="isLoading" title="Processing...">
    <base-spinner></base-spinner>
  </base-dialog>
  <div class="container">
    <b class="float" @click="getpdf">
      <router-link to="">
        <h2 class="fa fa-download my-float text-white"></h2>
      </router-link>
    </b>

    <div class="row">
      <div class="col-sm-6"><h1>Report</h1></div>
      <div class="col-sm-6">
        <label for="project_id">Select A Project</label>
        <!--<input type="text"
          id="project_id"
          ref="project_id"
          name="project_id"
          class="form-control form-control-sm"
          v-model.trim="project_id" list="projects" @change="ChangeProject($event)"/>
          <datalist id="projects" class="form-control form-control-sm">
            <option
            v-for="option in ProjectOption"
            :key="option.int_project_id"
            :value="option.txt_project_name"
            :data-value="option.int_project_id"
          />
          </datalist>-->
        <select
          type="text"
          id="project_id"
          ref="project_id"
          name="project_id"
          class="form-control form-control-sm"
          v-model.trim="project_id"
          @change="ChangeProject($event)"
        >
          <option
            v-for="option in ProjectOption"
            :key="option.int_project_id"
            :value="option.int_project_id"
          >
            {{ option.txt_project_name }}
          </option>
        </select>
      </div>
    </div>
    <div class="py-2 table_div">
        <table class="table table-striped border">
        <thead>
            <tr>
            <th scope="col">Date</th>
            <th scope="col">Details</th>
            <th scope="col">Expences</th>
            <th scope="col">Payment</th>
            <th scope="col">Worker</th>
            </tr>
        </thead>
        <tbody>
          <tr v-for="data in setReport" :key="data.serial_number">
            <th scope='row'>{{data.dt_transaction}}</th>
            <td>{{data.transaction_detail}}</td>
            <td>{{data.expenses}}</td>
            <td>{{data.receive}}</td>
            <td>{{data.by_user}}</td>
          </tr>
        </tbody>
    </table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ProjectOption: null,
      project_id:null,
      project_name:null,
      isLoading:false,
      purchaseList:false,
      reportRow:"<tr><td colspan='5' class='text-center'>Select Any Project <td/><tr/>",
    };
  },
  methods: {
    ChangeProject(event){
        var id=event.target.value;
        this.project_name = event.target.options[event.target.options.selectedIndex].text;
        this.$store.dispatch("report/getProjectReport", { id: id });
    },
    
    getOption() {
      this.$store.dispatch("report/getOption", { id: 0 });
      this.isLoading = true;
      setTimeout(() => {
        this.setOption();
        this.isLoading = false;
      }, 1500);
    },
    setOption() {
      this.ProjectOption = this.$store.getters["report/getProjectOption"];
    },getpdf(){
        var client_option='';
        var year='';
        var month='';
        var gst_type='';
        var gst_type_2='';
        var platform_option='';
        var dt_from='';
        var dt_to='';
        var project_id=this.project_id;
        var project_name = this.project_name;
            /*try{
                this.$store.dispatch('pdf/getpdf',{page_name:this.heading});
            }catch(error){
               console.log('error'+error)
            }*/
             window.open(`http://estimate.dechome.in/Database/pdf_download.php?project_name=${project_name}&page_name=report&client_option=${client_option}&year=${year}&month=${month}&gst_type=${gst_type}&gst_type_2=${gst_type_2}&platform_option=${platform_option}&dt_from=${dt_from}&dt_to=${dt_to}&project_id=${project_id}&uid=''`);
          
        }, 
        
  },computed:{
     
  setReport(){
       return this.$store.getters['report/getProjectReport'];
    },     
  },created() {
      this.getOption();
  },
};
</script>
